@use "@general/styles/bootstrap/boostrap_configuration" as bst;
.footer{
    background-color: bst.$gray-900;
    .contact_block{
        padding-left: 110px;
        position: relative;
        .footer_logo{
            background-color: rgba($color: bst.$white, $alpha: 0.5);
            border-radius: 58px;
            padding: 1px;
            position: absolute;
            top: 0;
            left: 20px;
        }
        .contact_list{
            list-style: none;
            padding-left: 0;
        }
    }
    
    
}